<template>
    <div v-title data-tittle="杭州港快科技有限公司">
        <div class="header">
			<div class="header_left">
				<img src="../assets/logo.png" >
			</div>
			<div class="header_right">
				<a href="###"  @click="moveTo(1)" :class="{active:1 === index}" class="link">首页</a>
				<a href="###"  @click="moveTo(2)" :class="{active:2 === index}" class="link">功能介绍</a>  
				<a href="###"  @click="moveTo(3)" :class="{active:3 === index}" class="link">软件下载</a>  
				<a href="###"  @click="moveTo(4)" :class="{active:4 === index}" class="link">关于我们</a>  
				<a href="###"  @click="moveTo(5)" :class="{active:5 === index}" class="link">联系我们</a>  
			<!--<a href="###"><button class="button01">商家登录</button></a>
				<a href="###"><button class="button02">免费注册</button></a> -->
			</div>
        </div>
        <div class="one">
			<h1>聚配骑</h1>
			<p class="oder_txt">订单聚合配送管理系统</p>
			<div class="eng">Order aggregation distribution</div>
			<!-- <a href="###"><button type="button">免费注册</button></a> -->
		</div>
        <div class="two">
			<p class="two-title01">JUPEIQI</p>
			<p class="two-title02">订单聚合配送管理系统</p>
			<p class="two-title03">让我们与您一起成长</p>
			
			<div class="two-wrap">
				<div class="two-wrap-01">
					<div class="two-wrap-02">
						<img src="../assets/icon_01.png" >
					</div>
					<div class="two-wrap-03">多平台订单同步管理</div>
					<div class="two-wrap-04">接入美团、饿了么等第三方平台，协助商户管理多个平台的订单</div>
				</div>
				
				<div class="two-wrap-01">
					<div class="two-wrap-02">
						<img src="../assets/icon_02.png" >
					</div>
					<div class="two-wrap-03">多渠道配送运力聚合</div>
					<div class="two-wrap-04">聚合达达、UU跑腿、闪送等第三方配送渠道，一单多投提高配送接单效率</div>
				</div>
				
				<div class="two-wrap-01">
					<div class="two-wrap-02">
						<img src="../assets/icon_03.png" >
					</div>
					<div class="two-wrap-03">自动打印小票</div>
					<div class="two-wrap-04">适配市场主流云打印平台，通过简单配置即可实现小票云打印</div>
				</div>
				<div class="two-wrap-01">
					<div class="two-wrap-02">
						<img src="../assets/icon_04.png" >
					</div>
					<div class="two-wrap-03">自动配送发单</div>
					<div class="two-wrap-04">支持开启自动发单功能，实现新订单自动发单，预约单指定时间自动发单，无须盯着手机和电脑</div>
				</div>
				
			</div>
			
			
		</div>
        <div class="three">
			<p class="two-title01">JUPEIQI</p>
			<p class="two-title02">软件下载</p>
			<p class="two-title03">全面解决订单处理、配送发单、渠道运营，打造订单全链路闭环</p>
			
			<div class="three-wrap">
				<div class="three-wrap-l">
					<div class="three-wrap-l1">扫码下载</div>
					<div class="three-wrap-l2">聚配骑</div>
					<div class="three-wrap-l3"><img src="../assets/code.png" ></div>
				</div>
				<div class="three-wrap-r">
					<div class="iphone-01"><img src="../assets/iphone1.png" ></div>
					<div class="iphone-02"><img src="../assets/iphone2.png" ></div>
				</div>
			</div>
			
		</div>
		<div class="four">
			<p class="two-title02">关于我们</p>
			<p class="two-title03">COMPANY INTRODUCTION</p>
			<div class="four-wrap">
				<div class="img-warp">
					<div class="img-warp-left">
							<h1>杭州港快科技有限公司</h1>
							<p>杭州港快科技有限公司是互联网智慧服务和美好生活综合服务商，专注于智能应用服务领域。公司总部位于风景秀丽的西湖畔</p>
					</div>
					<div class="img-warp-right">
						<img src="../assets/e3979aa24a04f47733d114659ea0750f@2x.png" >
					</div>
					
				</div>
				<div class="img-warp-bottom">
					<p>港快在创办之初就坚定不移得秉承“万众创新”的原则，以持续创新的力量，源源不断地为用户提供品质服务，自主研发聚配骑订单配送聚合服务平台。这是一款为实体店铺商家而打造的订单管理系统,主 要接入各种零售、外卖平台和配送渠道,为商家提供智能快速的大量订单信息处理和聚合配送功能,有效的提高零售、外卖商家订单的配送效率。      </p>
					<p>港快科技汇聚业内大咖，融合优秀行业资源创建而成。公司核心管理团队均是来自于互联网公司等相关领域的业内精英，既有博学精深的理论造诣，又具有丰富强大的实践经验。在企业发展过程中，我们 始终坚持稳步发展，持续创新，开放合作，旨在为全国商家在零售、外卖等领域提供卓越智能的一站式多元化服务。</p>
					<p>未来，我们将继续以人为本，秉承精益求精的态度，聚焦行业发展新模式，精耕细作，砥砺前行，以科技力量创造人与生活、自然的无限共连可能，实现可持续增长。</p>
				</div>
			</div>
			
		</div>
		<div class="five">
			<div class="concat-us">
				<div class="concat-us-wrap">
					<div class="concat-us-left">
						<h1>联系我们</h1>
						<h2>杭州港快科技有限公司</h2>
						<p>联系地址：浙江省杭州市西湖区文一西路522号西溪科创园9幢2单元601室</p>
						<p>联系电话：18957151331</p>
						<p>邮箱地址：108425728@qq.com</p>
					</div>
					<div class="concat-us-right">
						<img class="adress-01" src="../assets/adress.png" >
						<img class="adress-02" src="../assets/about.png" >
					</div>
				</div>
				
				<div class="xian"></div>
				<p class="beian">杭州港快科技有限公司 copyright © <span style="color: #2354F3 ;">2021-2022 jupeiqi.com</span> all right reserved</p>
				<p class="beian">ICP备案号：<a href="https://beian.miit.gov.cn/#/Integrated/index"><span style="color: #2354F3 ;">浙ICP备2021034602号-2</span></a></p>
			</div>
			
			
		</div>
    </div>
</template>

<script>

export default {
    name: "index",
    data(){
        return{
			index:1,
        }
    },
    methods:{
        moveTo(item){
			this.index = item;
            let className = '';
            switch (item) {
                case 1:
                    className = 'one';
                    break;
                case 2:
                    className = 'two';
                    break;
                case 3:
                    className = 'three';
                    break;
				case 4:
				    className = 'four';
					break;
			    case 5:
					className = 'five';
					break;
            }
            let distance = document.getElementsByClassName(className)[0].offsetTop;
            window.scrollTo({
                top: distance - 73,
                behavior: "smooth"
            });
        }
    },
    mounted() {

    }
}
</script>

<style scoped>
.one, .two, .three,.four,.five{
    height: 650px;
}
.header{
	padding:0 50px;
	background-color: #ffffff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	z-index: 11;
}

.header .header_left img{
	width: 376px;
	height: 70px;
	
}
.header_right{
	width: 1000px;
	margin-left: 165px;
}
a {text-decoration: none;}
.link{font-size: 15px;color: #808080;} 
.link:before{content: "/";margin:0 24px;color: grey;}   
.link:first-child:before{display:none;}  
.active {
	color: #2354F3;
 }
 button{
 	border: 0;
 	background-color: transparent;
 	outline: none;
 }
 
 .header_right .button01{
 	background-color: #ffffff;
 	border: 1px solid #000000 ;
 	color: #000000;
 	border-radius: 30px;
 	font-size: 12px;
 	padding: 4px 5px;
 	margin-left: 40px;	
 }
 
 .header_right .button02{
 	background-color: #2354F3;
 	color: #ffffff;
 	padding: 4px 5px;
 	border-radius: 30px;
 	font-size: 12px;
 	margin-left: 10px;	
 }
 
 /* one */	 
 .one{
	background-image: url(../assets/banner.png);
	background-size: 100% 100%;
	background-repeat:no-repeat; 
 }	 
 .one h1{
	padding: 203px 0 0 250px;
	font-size: 60px;
	font-weight: bold;
	color: #31384E;
    font-family: Microsoft YaHei;
	letter-spacing: 5px;
	 
 }
 .one .oder_txt{
 	margin: 20px 0 0 250px;
 	font-size: 30px;
 	letter-spacing: 0.5rem;
 	color: #31384E;
 }
 .one .eng{
 	margin: 30px 0 0 250px;
 	font-size: 15px;
 	color: #697598;
 }
 .one button{
 	margin: 50px 0 0 250px;
 	font-size: 15px;
 	color: #697598;
	border-radius: 20px;
    padding: 10px 40px;
	color: #ffffff;
	border: 0;
	background:linear-gradient(to left,#2655EE ,#0B42F5 );
 }
 
 /* two */
 
 .two{
     background: #ffffff;
	 padding-top: 20px;
 }
 .two-title01{ 
	 text-align: center;
	 font-size: 20px;
	 font-family: Open Sans;
	 font-weight: 400;
	 color: #0B42F5;
	 opacity: 0.5;
 }
 .two-title02{
	 text-align: center;
 	 font-size: 25px;
 	 font-family: Microsoft YaHei;
 	 font-weight: 400;
	 margin-top: 20px;
 	 color: #31384E;
 	 opacity: 1;
 }
 .two-title03{
	 text-align: center;
 	 font-size: 15px;
 	 font-family: Microsoft YaHei;
 	 font-weight: 400;
 	 color: #697598;
	 margin-top: 25px;
 	 letter-spacing: 2px;
 	 opacity: 0.8;
 }
 .two-wrap{
	 padding:  10px 0 50px 30px;
	 margin-top: 40px;
	 display: flex;
	 flex-direction: row;
	 justify-content: space-around;
 }
 .two-wrap-01{
	 width: 240px;
	 height: 300px;
	 padding:  40px 0 0 30px;
	 background: #FFFFFF;
	 box-shadow: 0px 2px 20px rgba(49, 56, 78, 0.1);
	 opacity: 1;
	 border-radius: 10px 20px 10px 60px;
 }
	 
 .two-wrap-02{
	 width: 40px;
	 height: 40px; 
 }
 .two-wrap-02 img{
 	 width: 70px;
 	 height: 70px; 
 }
 .two-wrap-03{
 	 font-size: 16px;
 	 font-family: Microsoft YaHei;
 	 font-weight: bold;
 	 line-height: 83px;
 	 color: #31384E;
 	 opacity: 1; 
	 margin-top: 30px;
 }
 .two-wrap-04{
 	 width: 180px;
 	 font-size: 16px;
 	 font-family: Microsoft YaHei;
 	 font-weight: 300;
 	 color: #999999;
 	 opacity: 1;
 }
 
 /* three */
 .three{
	 padding-top: 20px;
     background: #ffffff;
 }
 .three-wrap{
	 display: flex;
	 flex-direction: row;
 }
 .three-wrap-l1{
	 font-size: 30px;
	 font-family: Microsoft YaHei;
	 font-weight: 400;
	 color: #999999;
	 margin:  70px 0 0 260px;
	 opacity: 1;
 }
 .three-wrap-l2{
 	 font-size: 30px;
 	 font-family: Microsoft YaHei;
 	 font-weight: 400;
 	 color: #31384E;
	 margin:  3px 0 0 260px;
 	 opacity: 1;
 }
 .three-wrap-l3{
 	width: 120px;
	height: 120px;
	margin:  50px 0 0 260px;
 }
 .three-wrap-l3 img{
 	width: 120px;
 	height: 120px;
 }
 
	 
 .three-wrap-r{
	 width: 600px;
	 height: 350px;
	 display: flex;
	 flex-direction: row;
	 margin:50px 0 0 230px;
	 overflow: hidden;
	 
 }
 .three-wrap-r .iphone-01{
 	 width: 300px;
 	 height: 350px;
	 z-index: 1;
 }
 .three-wrap-r .iphone-01 img{
 	 width: 300px;
	 height: 500px;
 }
 .three-wrap-r .iphone-02{
 	 width: 260px;
 	 height: 400px;
	 margin-top: 100px;
	 margin-left: -67px;
 }
 .three-wrap-r .iphone-02 img{
 	 width: 260px;
 }
 .four{
     background: #F8F9FE;
	 padding-top: 50px;
 }
 
 .img-warp{
	 display: flex;
	 flex-direction: row;
	 justify-content: center;
	 margin: 0 auto;
	 margin-top: 50px;
 }
 
 .img-warp-left{
	/* padding: 20px 0 20px 20px; */
	 width: 400px;
	 height: 200px;
	 background: #2354F3;
	 opacity: 1;
	 border-radius: 12px;
	 
 }
 
 .img-warp-left h1{
	 font-size: 20px;
	 font-family: Microsoft YaHei;
	 font-weight: 400;
	 line-height: 50px;
	 color: #FFFFFF;
	 opacity: 1;
	 margin: 20px 0 20px 20px;
 }
 .img-warp-left p{
	 width: 320px;
 	 font-size: 13px;
 	 font-family: Microsoft YaHei;
 	 font-weight: 400;
 	 line-height: 25px;
 	 color: #FFFFFF;
 	 opacity: 0.5;
	 margin-left: 20px;
 }
 
 .img-warp-right{
	 width: 700px;
	 height: 200px;
	 margin-left: -50px;
 }
 .img-warp-right img{
 	 width: 700px;
	 margin-top: 20px;
	 
 }
 
 
 .img-warp-bottom{
	 width: 1050px;
	margin:  0 auto;
	margin-top: 80PX;
	font-size: 13px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	line-height: 26px;
	color: #31384E;
	opacity: 0.5;
	text-indent:2rem ;
 }
 
 .five{
     background: #ffffff;
 }
 
 .concat-us-wrap{
	 padding-top: 150px;
	 display: flex;
	 flex-direction: row;
	 justify-content: center;
 }
 .concat-us-left h1{
	 font-size: 30px;
	 font-family: Microsoft YaHei;
	 font-weight: bold;
	 line-height: 65px;
	 color: #2354F3;
	 opacity: 1;
 }
 .concat-us-left h2{
 	 font-size: 20px;
 	 font-family: Microsoft YaHei;
 	 font-weight: bold;
 	 line-height: 28px;
 	 color: #31384E;
 	 opacity: 0.9;
 }

 .concat-us-left p{
 	 font-size: 13px;
 	 font-family: Microsoft YaHei;
 	 font-weight: 400;
 	 line-height: 5px;
 	 color: #31384E;
 	 opacity: 0.6;
	 margin-top: 30px;
 }
	  
  .concat-us-right{
	  position: relative;
  }
 .adress-01{
	 position: relative;;
	 width: 700px;
	 margin-left: 100px;
	 
 }
 .adress-01 img{
 	 width: 700; 
 }
 
 .adress-02{
 	 width: 300px;
	 position: absolute;
	 left: 300px;
	 top: 50px;
	 z-index: 1;
 	 
 }
 .adress-02 img{
	 z-index: 1;
 	 width: 300px; 
	 height: 270px;
 }
 
 .xian{
	 height: 0px;
	 border: 1px solid #31384E;
	 opacity: 0.2;
	 width: 1050px;
	 margin: 0 auto;
	 margin-top: 150px;
 }
 
 .beian{
	 font-size: 15px;
	 font-family: Open Sans;
	 font-weight: 400;
	 line-height: 20px;
	 color: #AFB2C1;
	 opacity: 0.7;
	 text-align: center;
	 margin-top: 20px;
 }
 
</style>