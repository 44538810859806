// rem 等比适配配置文件
// 基准大小
const baseSize = 14;
// 设置适配函数
function setRem (){
	// 当前页面相对于1920缩放比例,可根据具体需求调整
	const scale = document.documentElement.clientWidth / 1920;
	// 设置页面根节点字体大小(Math.min(scale, 2) 指最大放大比例为2,可根据具体业务需求调整)
	document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px';
}
// 初始化
setRem();
// 改变窗口大小时,重新设置 rem
window.onresize = function (){
	setRem();
}